.modernTableStyle {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0 2px 4px rgba(124, 124, 124, 0.1);
  font-family: 'Segoe UI', Arial, sans-serif;
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.modernHeaderStyle {
  background-color: #553C9A;
  color: rgb(255, 255, 255);
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}
.modernHeaderStyle::after {
  content: '';
  display: inline-block;
}

.tableData {
  padding: 10px;
  border-bottom: 2px solid #28243c; /* Slightly lighter than the background for subtle separation */
  color: white; /* Default text color */
  text-align: center;
}

.rowHover:hover td {
  background-color: rgba(68, 37, 126, 0.2);
  color: #ffffff; /* Darker text color for better visibility */
}


.statusOnline {
  background-color: #4CAF50;
  border-radius: 1px;
  color: white; /* Ensuring visibility */
  font-weight: bold;
}

.statusOffline {
  background-color: #F44336;
  color: white; /* Ensuring visibility */
    font-weight: bold;
}
.statusPending {
  background-color: #FFC107;
  color: white; /* Ensuring visibility */
    font-weight: bold;
}

.logoutButton {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #007bff;
  float: right;
  height: 50px;
  width: 100px;
}