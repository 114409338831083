.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    padding: 20px;
  }

  .login-form {
    flex: 3;
    max-width: 600px;
    margin-left: 50px;
  }
  
  .login-image {
    flex: 1;
    max-width: 400px;
  }
  
  .login-image img {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: adds rounded corners to the image */
  }
  
  .h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 60px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: white;
    margin-top: 55px;
    font-size: 20px;

  }
  
  .form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 70%;
    max-width: 300px;
  }
  
  button {
    width: 70%;
    max-width: 300px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #007bff;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  